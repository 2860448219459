import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "react-tooltip"
import { InTextLink } from "../../../kit/Badges"
import { useMemo, useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useProviders, useStorageSection } from "./ProvidersCommon"
import { FullScreenLoader } from "../../../kit/Spinners"

const parseConnectionString = cs => {
    if (!cs) return {
        bucketArn: '',
        pointArn: '',
        httpHeaders: '',
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            bucketArn: '',
            pointArn: partsDict['Arn'],
            httpHeaders: partsDict['Headers'] !== undefined ? atob(partsDict['Headers']) : '',
        }
    )
}
export const AmazonS3AccessPointStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const [bucketArn, setBucketArn] = useState(parsedConnectionString.bucketArn);
    const [pointArn, setPointArn] = useState(parsedConnectionString.pointArn);
    const [httpHeaders, setHttpHeaders] = useState(parsedConnectionString.httpHeaders);


    const buildConnectionString = () => `Arn=${pointArn};Headers=${btoa(httpHeaders)}`;
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const bucketPolicy = () => {
        if (bucketArn === '' || storageEditor.providers.isLoading) return '';
        var policyTemplate = storageEditor.providers.list["AmazonS3AccessPoint"].userInterfaceHelper.parameters.BucketPolicyTemplate;
        var principal = storageEditor.providers.list["AmazonS3AccessPoint"].userInterfaceHelper.parameters.Principal;
        policyTemplate = policyTemplate.replace(new RegExp("{{CLOUDBACK USER ARN}}", "g"), principal);
        policyTemplate = policyTemplate.replace(new RegExp("{{CLIENT BUCKET ARN}}", "g"), bucketArn);
        return policyTemplate;
    }

    const pointPolicy = () => {
        if (pointArn === ''|| storageEditor.providers.isLoading) return '';
        var policyTemplate = storageEditor.providers.list["AmazonS3AccessPoint"].userInterfaceHelper.parameters.AccessPointPolicyTemplate;
        var principal = storageEditor.providers.list["AmazonS3AccessPoint"].userInterfaceHelper.parameters.Principal;
        policyTemplate = policyTemplate.replace(new RegExp("{{CLOUDBACK USER ARN}}", "g"), principal);
        policyTemplate = policyTemplate.replace(new RegExp("{{CLIENT ACCESS POINT ARN}}", "g"), pointArn);
        return policyTemplate;
    }

    const canContinue = pointArn !== '';

    return (
        <>
            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWithTextInput stepTitle="Step 1" placeholder="arn:aws:s3:::"
                    value={bucketArn} setValue={setBucketArn}
                >
                    <p>
                        Create an <InTextLink url="https://docs.aws.amazon.com/AmazonS3/latest/userguide/GetStartedWithS3.html#creating-bucket" title="Amazon S3 Bucket" />
                        {' '}and type a <b>Bucket ARN</b> below:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 2"
                    copy={true}
                    readonly={true}
                    value={bucketPolicy()}
                >
                    <p>
                        Copy the generated <InTextLink url="https://docs.aws.amazon.com/AmazonS3/latest/userguide/add-bucket-policy.html" title="bucket policy" /> document to your clipboard, and then apply it to your bucket:
                    </p>
                </ProviderStepWithTextArea>
                <ProviderStepWithTextInput stepTitle="Step 3" placeholder="arn:aws:s3:"
                    value={pointArn} setValue={setPointArn}
                >
                    <p>
                        Create an <InTextLink url="https://docs.aws.amazon.com/AmazonS3/latest/userguide/creating-access-points.html" title="Amazon S3 Access Point" />
                        {' '}and type an <b>Access Point ARN</b> below:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 4"
                    readonly={true}
                    copy={true}
                    value={pointPolicy()}
                >
                    <p>
                        Copy the generated access point policy document to your clipboard, and then <InTextLink url="https://docs.aws.amazon.com/AmazonS3/latest/userguide/access-points-manage.html#edit-access-point-policy" title="apply" /> it to your access point:
                    </p>
                </ProviderStepWithTextArea>
                <ProviderStepWithTextArea
                    stepTitle="Step 5 (optional)"
                    readonly={true}
                    value={httpHeaders}
                    setValue={setHttpHeaders}
                >
                    <p>
                        Provide additional HTTP headers, if required {'('}for example, for <InTextLink url="https://docs.cloudback.it/security-and-compliance/amazon-s3-object-lock" title="S3 Object Lock" />{')'}:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided} canContinue={canContinue} />
        </>
    )
}