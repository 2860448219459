import { InTextLink } from "../../../kit/Badges"
import { useMemo, useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWithDoubleTextInput, ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"

const parseConnectionString = cs => {
    if (!cs) return {
        containerName: '',
        keyId: '',
        keySecret: '',
        httpHeaders: '',
        endpointUrl: ''
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            containerName: partsDict['BucketName'],
            keyId: partsDict['AccessKeyId'],
            keySecret: partsDict['AccessKeySecret'],
            httpHeaders: partsDict['Headers'] !== undefined ? atob(partsDict['Headers']) : '',
            endpointUrl: partsDict['ServiceUrl'],
        }
    )
}

export const SwiftContainerAccessKeyStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const [containerName, setContainerName] = useState(parsedConnectionString.containerName);
    const [keyId, setKeyId] = useState(parsedConnectionString.keyId);
    const [keySecret, setKeySecret] = useState(parsedConnectionString.keySecret);
    const [httpHeaders, setHttpHeaders] = useState(parsedConnectionString.httpHeaders);
    const [endpointUrl, setEndpointUrl] = useState(parsedConnectionString.endpointUrl);

    const buildConnectionString = () => `BucketName=${containerName};AccessKeyId=${keyId};AccessKeySecret=${keySecret};ServiceUrl=${endpointUrl};Headers=${btoa(httpHeaders)}`;
    const canContinue = endpointUrl !== '' && containerName !== '' && keyId !== '' && keySecret !== '';

    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    return (
        <>
            <StorageWrapperComponent className="bg-blue-50/20" providers={storageEditor.providers}>
                <ProviderStepWithTextInput stepTitle="Step 1" placeholder="http(s)://"
                    value={endpointUrl} setValue={setEndpointUrl}
                >
                    <p>
                        Provide an <InTextLink url="https://wiki.openstack.org/wiki/Swift" title="OpenStack Swift" /> Server URL:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextInput stepTitle="Step 2" placeholder=""
                    value={containerName} setValue={setContainerName}
                >
                    <p>
                        Provide a <InTextLink url="https://docs.openstack.org/newton/user-guide/cli-swift-create-containers.html" title="Container" /> name:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithDoubleTextInput
                    stepTitle="Step 3"
                    firstTitle="Access Key ID"
                    secondTitle="Access Key Secret"
                    firstPlaceholder=""
                    secondPlaceholder=""
                    firstValue={keyId}
                    setFirstValue={setKeyId}
                    secondValue={keySecret}
                    setSecondValue={setKeySecret}
                >
                    <p>
                        Provide an Access Key of <InTextLink url="https://docs.openstack.org/python-openstackclient/latest/cli/command-objects/ec2-credentials-v3.html" title="ec2 credentials" />
                        {' ('}<InTextLink url="https://docs.openstack.org/mitaka/config-reference/object-storage/configure-s3.html" title="reference link" />{')'}:
                    </p>
                </ProviderStepWithDoubleTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 4 (optional)"
                    value={httpHeaders}
                    setValue={setHttpHeaders}
                >
                    <p>
                        Provide additional HTTP headers, if required {'('}for example, for <InTextLink url="https://docs.cloudback.it/security-and-compliance/amazon-s3-object-lock" title="S3 Object Lock" />{')'}:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided}  canContinue={canContinue} />
        </>
    )
}