import { useEffect, useState } from "react";
import { UserSelector } from "./AccountPage";
import { classNames } from "../application/ApplicationHeader";
import axios from "axios";
import { settings } from "../../settings";
import { CircularSpinnerSecondary, FullScreenLoader, NewLoader } from "../../kit/Spinners";
import { InstantNotificationMessengerType, InstantNotificationType } from "../../model/domain";
import { LoadingAutocompleteInner, useAutocomplete } from "../../kit/Autocomplete";
import { SelectBox } from "../../kit/SelectBox";
import { useDispatch } from "react-redux";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { ButtonType, FormButton } from "../../kit/Buttons";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";
import { EmptyInstallationPage } from "../application/EmptyInstallationPage";

const notificationTypeOptions = [
    { id: InstantNotificationType.disabled, label: "Disabled" },
    { id: InstantNotificationType.onCompletion, label: "On backup completion" },
    { id: InstantNotificationType.onFailureOnly, label: "On backup failure only" },
]

const notificationMessengerTypeOptions = [
    { id: InstantNotificationMessengerType.discord, label: "Discord" },
    { id: InstantNotificationMessengerType.microsoftTeams, label: "Microsoft Teams" },
    { id: InstantNotificationMessengerType.slack, label: "Slack" },
]

const findById = (options, id) => {
    let index = options.findIndex(x => x.id === id);
    if (index !== -1) return options[index];
    return options[0];
}

const MessengersNotificationsBlock = ({ isExternalLoading, accountName, setErrorPage }) => {

    const [instantNotificationType, setInstantNotificationType] = useState(null);
    const [instantNotificationMessengerType, setInstantNotificationMessengerType] = useState(null);
    const [instantNotificationMessengerWebhookUrl, setInstantNotificationMessengerWebhookUrl] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const isCombinedLoading = isExternalLoading || isLoading;
    const [saveBusy, setSaveBusy] = useState(false);
    const [testBusy, setTestBusy] = useState(false);
    const dispatch = useDispatch();
    const [notifySuccess] = useNotifier();
    const saveCurrentSettings = () => {
        setSaveBusy(true);
        axios.post(settings.backendUrl + "/v2/notifications/setMessenger",
            {
                accountName: accountName,
                settings: {
                    instantNotificationType: instantNotificationType.id,
                    instantNotificationMessengerType: instantNotificationType.id === InstantNotificationType.disabled ?
                        InstantNotificationMessengerType.none : instantNotificationMessengerType.id,
                    instantNotificationMessengerWebhookUrl: instantNotificationType.id === InstantNotificationType.disabled ? null : instantNotificationMessengerWebhookUrl
                }
            })
            .then(res => {
                if (res.data.isSuccess) {
                    notifySuccess("Saved");
                    setSaveBusy(false);
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to save", text: res.data.errorDescription }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error occured", text: "Please try again or contact support" }));
            })
            .finally(() => {
                setSaveBusy(false);
            })
    }
    const testCurrentSettings = () => {
        setTestBusy(true);
        axios.post(settings.backendUrl + "/v2/notifications/testMessenger",
            {
                instantNotificationMessengerType: instantNotificationMessengerType.id,
                instantNotificationMessengerWebhookUrl: instantNotificationMessengerWebhookUrl
            })
            .then(res => {
                if (res.data.isSuccess) {
                    dispatch(setModal({ id: Modals.SuccessModalWithSingleCloseButton, title: "Test notification sent" }));
                    setTestBusy(false);
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Test failed", text: res.data.errorDescription }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error occured", text: "Please try again or contact support" }));
            })
            .finally(() => {
                setTestBusy(false);
            })
    }
    const getCurrentSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/notifications/messenger?accountName=" + accountName)
            .then(res => {
                if (res.data.isSuccess) {
                    setInstantNotificationMessengerWebhookUrl(res.data.result.instantNotificationMessengerWebhookUrl);
                    setInstantNotificationMessengerType(findById(notificationMessengerTypeOptions, res.data.result.instantNotificationMessengerType));
                    setInstantNotificationType(findById(notificationTypeOptions, res.data.result.instantNotificationType));
                }
                else{
                    setErrorPage(res.data.errorDescription);
                }
            })
            .catch(e=>{
                processAxiosError(e);
                setErrorPage("The settings couldn't be loaded. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (accountName) {
            getCurrentSettings();
        }
    }, [accountName])

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Instant Messenger Notifications</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">It is sent immediately after an event occurs. Each notification is per repository. Be careful, because if you have a large number of repositories, there may be a notification storm.
                {' '}<a className="underline" href="https://docs.cloudback.it/managing-backups/instant-notifications">Learn more</a> on our documentation website.</p>

            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 rounded-lg bg-white shadow p-3">
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        Notifications
                    </label>
                    <SelectBox
                            isLoading={isCombinedLoading}
                            options={notificationTypeOptions}
                            selected={instantNotificationType}
                            setSelected={setInstantNotificationType}
                            keySelector={v => v?.id}
                            labelSelector={v => v?.label}
                        />
                </div>
                {!isCombinedLoading && instantNotificationType.id !== InstantNotificationType.disabled &&
                    <>
                        <div className="sm:col-span-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Messenger
                            </label>
                            <SelectBox
                                options={notificationMessengerTypeOptions}
                                selected={instantNotificationMessengerType}
                                setSelected={setInstantNotificationMessengerType}
                                keySelector={v => v.id}
                                labelSelector={v => v.label}
                            />
                        </div>
                        <div className="sm:col-span-full">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Webhook Url
                            </label>
                            <div className="mt-2">
                                <input
                                    placeholder="empty"
                                    className="rounded-md resize-none block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                    value={instantNotificationMessengerWebhookUrl}
                                    onChange={(e) => setInstantNotificationMessengerWebhookUrl(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                }
                <div className="sm:col-span-full">
                    <div className="sm:mt-4 sm:flex sm:flex-row-reverse">
                        <FormButton onClick={saveCurrentSettings} disabled={testBusy || saveBusy} loading={isCombinedLoading} btnText={saveBusy ? "Saving" : "Save"}
                        />       
                        <FormButton onClick={testCurrentSettings} 
                        disabled={testBusy || saveBusy || (!isCombinedLoading && instantNotificationType.id === InstantNotificationType.disabled)} 
                        loading={isCombinedLoading} btnText={testBusy ? "Testing" : "Test"} 
                        buttonType={ButtonType.Secondary}  
                        tooltipId="test-disabled" tooltipText={isCombinedLoading?"Loading":"No options selected to test"} 
                        showTooltip={isCombinedLoading || instantNotificationType.id === InstantNotificationType.disabled}/> 
                    </div>
                </div>
            </div>

        </div>
    )
}

const EmailNotificationsBlock = ({ isExternalLoading, accountName, setErrorPage }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const isCombinedLoading = isExternalLoading || isLoading;
    const [saveBusy, setSaveBusy] = useState(false);
    const [notifySuccess] = useNotifier();
    const dispatch = useDispatch();
    const saveCurrentSettings = () => {
        setSaveBusy(true);
        axios.post(settings.backendUrl + "/v2/notifications/setEmail",
            {
                accountName: accountName,
                settings: {
                    notificationEmailOverride: email
                }
            })
            .then(res => {
                if (res.data.isSuccess) {
                    setSaveBusy(false);
                    notifySuccess("Saved");
                }
                else{
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to save", text: res.data.errorDescription }));
                }
            })
            .catch(e=>{
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to save", text: "Please try again or contact support" }));
            
            })
            .finally(() => {
                setSaveBusy(false);
            })
    }
    const getCurrentSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/notifications/email?accountName=" + accountName)
            .then(res => {
                if (res.data.isSuccess) {
                    setEmail(res.data.result.notificationEmailOverride || '');
                }
                else{
                    setErrorPage(res.data.errorDescription);
                }
            })
            .catch(e=>{
                processAxiosError(e);
                setErrorPage("The settings couldn't be loaded. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (accountName) {
            getCurrentSettings();
        }
    }, [accountName])

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Email Notifications</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">It is sent at 22:00 UTC when an error occurs. All errors will be combined into one notification email per account. The email below is used for notifications about failed backups.
                If it is empty, the GitHub account primary email is used. <a className="underline" href="https://docs.cloudback.it/managing-backups/email-notifications">Learn more</a> on our documentation website.</p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-full">
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        Email
                    </label>
                    <div className="mt-2 flex flex-col sm:flex-row">
                        {isCombinedLoading ? <LoadingAutocompleteInner /> :
                            <input
                                placeholder="Empty, defaulted to GitHub account primary email"
                                className="rounded-md resize-none block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        }
                        <FormButton onClick={saveCurrentSettings} busy={saveBusy} loading={isCombinedLoading} btnText={saveBusy ? "Saving" : "Save"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const NotificationSettings = () => {
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    return pageError!==null?<ErrorPage />:(
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5 bg-gray-100">
            <div className="mx-auto max-w-3xl">
                <div className="space-y-6">
                    <UserSelector users={users} setUsers={setUsers} user={user} setPageError={setPageError} setUser={setUser} isLoading={isLoading} setIsLoading={setIsLoading} />
                    {!isLoading && user === null ?
                        <div className="border-b border-gray-900/10 pb-12">
                            <EmptyInstallationPage />
                        </div>
                        :
                        <>
                         <EmailNotificationsBlock isExternalLoading={isLoading} setPageError={setPageError} accountName={user?.accountName} />
                        <MessengersNotificationsBlock isExternalLoading={isLoading} setPageError={setPageError} accountName={user?.accountName} />
                        </>
                    }         
                </div>
            </div>
        </div>
    )
}