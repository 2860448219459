import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "react-tooltip"
import { InTextLink } from "../../../kit/Badges"
import { useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWithTextArea, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"


export const AzureStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const [connectionString1, setConnectionString] = useState(connectionString || '');
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, () => connectionString1, editMode, setPageError);

    return (
        <>
            <StorageWrapperComponent className="bg-blue-50/20" providers={storageEditor.providers}>
                <ProviderStepWrapper stepTitle="Step 1"
                >
                    <p>
                        Create an
                        <b> Azure Storage Account </b>
                        and a
                        <InTextLink url="https://learn.microsoft.com/en-us/azure/storage/blobs/blob-containers-portal#create-a-container" title=" Private Container " />
                        <b> Private Container </b>
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWrapper stepTitle="Step 2"
                >
                    <p>
                        Create a
                        <b> Blob Container Shared Access Signature (SAS) </b>
                        and grant
                        <b> Create</b>,
                        <b> Write</b>
                        <span className="align-sub">
                            <button
                                data-tooltip-id="azure-permissions-tooltip" data-tooltip-content="Why Write? Write is required by Azure for multipart uploads of large archives."
                                type="button"
                                disabled
                                className="rounded-full bg-white text-gray-600"
                            >
                                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </span>
                        <Tooltip id="azure-permissions-tooltip" />
                        , and
                        <b> Read </b> permissions. Grant
                        <b> Delete </b>permission if you want our retention policy to work properly.
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWithTextArea
                    stepTitle="Step 3"
                    value={connectionString1}
                    setValue={setConnectionString}
                >
                    <p>
                        Insert previously created <b>Blob SAS URL</b>:
                    </p>
                </ProviderStepWithTextArea>

            </StorageWrapperComponent>
            <ProviderTestAndSave canContinue={connectionString1 !== ''} nameIncluded={nameProvided}  storageEditor={storageEditor} />
        </>
    )
}