import { InTextLink } from "../../../kit/Badges"
import { useMemo, useState } from "react"
import { ProviderStepWithDoubleTextInput, ProviderStepWithSelectAndTextInput, ProviderStepWithTextArea, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"

const regions = [
    { id: "https://s3.wasabisys.com", name: "Wasabi US East 1 (N. Virginia)" },
    { id: "https://s3.us-east-2.wasabisys.com", name: "Wasabi US East 2 (N. Virginia)" },
    { id: "https://s3.us-central-1.wasabisys.com", name: "Wasabi US Central 1 (Texas)" },
    { id: "https://s3.us-west-1.wasabisys.com", name: "Wasabi US West 1 (Oregon)" },
    { id: "https://s3.ca-central-1.wasabisys.com", name: "Wasabi CA Central 1 (Toronto)" },
    { id: "https://s3.eu-central-1.wasabisys.com", name: "Wasabi EU Central 1 (Amsterdam)" },
    { id: "https://s3.eu-central-2.wasabisys.com", name: "Wasabi EU Central 2 (Frankfurt)" },
    { id: "https://s3.eu-west-1.wasabisys.com", name: "Wasabi EU West 1 (London)" },
    { id: "https://s3.eu-west-2.wasabisys.com", name: "Wasabi EU West 2 (Paris)" },
    { id: "https://s3.ap-northeast-1.wasabisys.com", name: "Wasabi AP Northeast 1 (Tokyo)" },
    { id: "https://s3.ap-northeast-2.wasabisys.com", name: "Wasabi AP Northeast 2 (Osaka)" },
    { id: "https://s3.ap-southeast-2.wasabisys.com", name: "Wasabi AP Southeast 2 (Sydney)" },
]

const findRegion = (r) => {
    let index = regions.findIndex(x => x.id === r);
    return index === -1 ? regions[0] : regions[index];
}

const parseConnectionString = cs => {
    if (!cs) return {
        bucketRegion: regions[0],
        bucketArn: '',
        keyId: '',
        keySecret: '',
        httpHeaders: ''
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            bucketArn: partsDict['BucketName'],
            keyId: partsDict['AccessKeyId'],
            keySecret: partsDict['AccessKeySecret'],
            httpHeaders: partsDict['Headers'] !== undefined ? atob(partsDict['Headers']) : '',
            bucketRegion: findRegion(partsDict['ServiceUrl']),
        }
    )
}
export const WasabiS3AccessKeyStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const [bucketRegion, setBucketRegion] = useState(parsedConnectionString.bucketRegion);
    const [bucketArn, setBucketArn] = useState(parsedConnectionString.bucketArn);
    const [keyId, setKeyId] = useState(parsedConnectionString.keyId);
    const [keySecret, setKeySecret] = useState(parsedConnectionString.keySecret);
    const [httpHeaders, setHttpHeaders] = useState(parsedConnectionString.httpHeaders);


    const buildConnectionString = () => `BucketName=${bucketArn};AccessKeyId=${keyId};AccessKeySecret=${keySecret};ServiceUrl=${bucketRegion.id};Headers=${btoa(httpHeaders)}`
    const canContinue = bucketArn !== '' && keyId !== '' && keySecret !== '';
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    return (
        <>
            <StorageWrapperComponent className="bg-green-50/20" providers={storageEditor.providers}>
                <ProviderStepWithSelectAndTextInput
                    stepTitle="Step 1"
                    selectTitle="Bucket Region"
                    inputTitle="Bucket ARN"
                    inputPlaceholder="arn:aws:s3:::"
                    inputValue={bucketArn}
                    setInputValue={setBucketArn}
                    options={regions}
                    selected={bucketRegion}
                    setSelected={setBucketRegion}
                    keySelector={o => o.id}
                    labelSelector={o => o.name}
                >
                    <p>
                        Create an <InTextLink url="https://docs.wasabi.com/docs/creating-a-bucket" title="Wasabi Bucket" />{' '}
                        and provide a <InTextLink url="https://docs.wasabi.com/docs/what-are-the-service-urls-for-wasabi-s-different-storage-regions" title="Bucket Region" />{' '}
                        and <b>Bucket ARN</b> below:
                    </p>
                </ProviderStepWithSelectAndTextInput>
                <ProviderStepWithDoubleTextInput
                    stepTitle="Step 2"
                    firstTitle="Access Key ID"
                    secondTitle="Access Key Secret"
                    firstPlaceholder=""
                    secondPlaceholder=""
                    firstValue={keyId}
                    setFirstValue={setKeyId}
                    secondValue={keySecret}
                    setSecondValue={setKeySecret}
                >
                    <p>
                        Create an <InTextLink url="https://docs.wasabi.com/docs/creating-a-user-account-and-access-key#assigning-an-access-key" title="Wasabi access key" /> and type its <b>id</b> and <b>secret</b> below:
                    </p>
                </ProviderStepWithDoubleTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 3 (optional)"
                    value={httpHeaders}
                    setValue={setHttpHeaders}
                >
                    <p>
                        Provide additional HTTP headers, if requred {'('}for example, for <InTextLink url="https://docs.cloudback.it/security-and-compliance/amazon-s3-object-lock" title="S3 Object Lock" />{')'}:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided}  canContinue={canContinue} />
        </>
    )
}