import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingAutocomplete, useAutocomplete } from "../../kit/Autocomplete";
import { wildcardMatch } from "../../helpers/internalFunctions";
import { BulkApplyBlock, BulkPage, BulkSettingsSection, filteredCardsSelector, useBulkSettings } from "./BulkCommon";
import axios from "axios";
import { getGitHubAvatarUrl, settings } from "../../settings";
import { loadDashboard } from "../../slices/dataSlice";
import { FullScreenLoader } from "../../kit/Spinners";
import { useMixedData } from "../../hooks/useMixedData";
import { ErrorPage } from "../../kit/ErrorPage";


const SelectorBlock = ({ accountSelector, pattern, onPattern }) => {

    return (
        <>
            <div className="sm:col-span-3 self-end">
                {accountSelector}
            </div>

            <div className="sm:col-span-3 self-end">
                <label htmlFor="repository-pattern" className="block text-sm font-medium leading-6 text-gray-900">
                    Type repository name pattern (wildcards supported)
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        value={pattern}
                        onChange={e => onPattern(e.target.value)}
                        name="repository-pattern"
                        id="repository-pattern"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
        </>
    )
}


const BulkForm = ({ setSelectedCount, customSetPageError }) => {

    const [accountNames, isLoading, pageError, tryAgain, setPageError] = useMixedData(()=>axios.get(settings.backendUrl + "/v2/dashboard"), 
    (res, dispatch) => dispatch(loadDashboard(res.data.result)), state => state.data.dashboard?.accountNames, customSetPageError,
    (data) => data !== undefined && data !== null && data.length > 0);
    const cards = useSelector(filteredCardsSelector);
    const [pattern, setPattern] = useState("*");
    const [selected, setSelected] = useState([]);
    const bulkSettings = useBulkSettings();  
    const [settingsLoading, setSettingsLoading] = useState(true);
    useEffect(() => {
        setSelectedCount(selected.length);
    }, [selected, setSelectedCount])
    const accountNamesDict = useMemo(()=>accountNames.reduce((acc, v) => { acc[v] = v; return acc; }, {}), [accountNames]);  
    const [selectedAccount, setSelectedAccount] = useState(null);
    const autocomplete = useAutocomplete(accountNamesDict, isLoading, undefined, selectedAccount, setSelectedAccount);

    useEffect(() => {
        if (isLoading || selectedAccount === null) return;
        setSelected(cards
            .filter((v) => v.repository.owner === selectedAccount && wildcardMatch(v.repository.name, pattern))
            .map(v => v.definitionId));
    }, [cards, pattern, isLoading, selectedAccount])

    const onApply = () => {
        bulkSettings.onApply(selected);
    }
    const [nothingChanged, setNothingChanged] = useState(true);
    const formLoading = isLoading || settingsLoading;
        return (
            <>
                <div className="space-y-6">
                    <div className="border-b border-gray-900/10 pb-6">
                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <SelectorBlock accountSelector={
                                <LoadingAutocomplete isLoading={autocomplete.isLoading}
                                    autocomplete={{
                                        ...autocomplete,
                                        labelSelector: (option) => option,
                                        keySelector: (option) => option,
                                        label: "Select GitHub account",
                                        optionLeadingContentSelector: (option) => <img
                                            className="h-5 w-5 rounded-md"
                                            src={getGitHubAvatarUrl(option)}
                                            alt=""
                                        />,
                                    }}
                                />
                            } pattern={pattern} onPattern={setPattern} />
                        </div>
                    </div>
                    <BulkSettingsSection
                        pageError={pageError}
                        setPageError={setPageError}
                        isLoading={settingsLoading}
                        setIsLoading={setSettingsLoading}
                        bulkSettings={bulkSettings}
                        selectedCount={selected.length}
                        description={<>
                            repositories that match <b>name pattern</b> of the account selected.{' '}
                            <a className="text-green-600 hover:text-green-500" href="https://docs.cloudback.it/managing-backups/bulk-operations">Learn more</a>.
                        </>} />
                </div>
                <BulkApplyBlock nothingChanged={selected.length === 0 || bulkSettings.nothingChanged} onApply={onApply} isApplying={bulkSettings.isApplying} isLoading={formLoading}/>              
            </>
        )
}

export const BulkSelected = () => {

    const [selectedCount, setSelectedCount] = useState(0);

    return (
        <BulkPage content={(spe)=><BulkForm customSetPageError={spe} setSelectedCount={setSelectedCount} />} />
    )
}